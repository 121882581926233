import React from "react"
import Box from '@mui/joy/Box';
import Chip from "@mui/joy/Chip";
import Skeleton from "@mui/joy/Skeleton";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { ColorPaletteProp } from '@mui/joy/styles';
import ListDivider from "@mui/joy/ListDivider";
import { CircularProgress, List, ListItem, ListItemContent } from "@mui/joy";
import Button from "@mui/joy/Button";
import { Add } from "@mui/icons-material";

import { useTerminsStore } from "../store/terminsStore"
import RowMenu from "./RowMenu";
import { useUserStore } from "../store/userStore";
import { Collapse } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import { AnimatePresence, easeIn, motion } from "framer-motion";

export function TerminsList(props: {setOpenModal: React.Dispatch<boolean>}) {

  const project_id = useTerminsStore((state) => state.project_id)
  const termins = useTerminsStore((state) => state.termins)
  const fetchTermins = useTerminsStore((state) => state.fetchTermins)
  const loading = useTerminsStore((state) => state.loading)

  const loggedIn = useUserStore((state) => state.loggedIn)

  React.useEffect(() => {
    fetchTermins()
  }, [project_id])

  if (termins.length === 0 && !loading) return (<></>)

  return (
    <Box
    width='100%'
      sx={{ display: {xs: 'block', sm: 'none' } }}
    >
    {(loading) ? <CircularProgress
      size="lg"
      sx={{mt: 3}}
      /> : <></>}
              {termins.map((termin) => {
          return (
              <List
                size="md"
                sx={{
                '--ListItem-paddingX': 0,
                }}
                key={termin.id}
              >
              <ListItem
                key={termin.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                }}
              >
              <AnimatePresence>
              <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{
                  ease: "anticipate",
                  duration: 0.5
                }}
              >
                <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                  <div>
                    <Typography fontWeight={600} gutterBottom>
                    <Skeleton loading={loading}>
                      <Chip
                        variant="soft"
                        size="md"
                        color={
                          {
                            V: 'warning',
                            N: 'primary',
                            K: 'success',
                          }[termin.termin_type] as ColorPaletteProp
                        }
                        >
                              {{
                                  V: "Vaja",
                                  N: "Nastop",
                                  K: "Koncert",
                              }[termin.termin_type]}
                          </Chip>
                          </Skeleton>
                          </Typography>
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'left',
                              justifyContent: 'left',
                              gap: 0.5,
                              mb: 0.5,
                              mt: 1,
                          }}
                      >
                          <Typography level="body-xs"><Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}>{termin.date}</Skeleton></Typography>
                          <Typography level="body-xs"><Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}>&bull;</Skeleton></Typography>
                          <Typography level="body-xs"><Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}>{termin.start.slice(0, 5)} - {termin.end.slice(0, 5)}</Skeleton></Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                          <Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}><Link href={`https://maps.google.com/?q=${termin.location.replaceAll(' ', '+')}`} target="_blank" level="body-xs">
                              {termin.location.split(",")[0]}
                          </Link></Skeleton>
                          <RowMenu termin={termin} setOpenModal={props.setOpenModal} />
                      </Box>
                    </div>
                </ListItemContent>
                              </motion.div>
              </AnimatePresence>
              </ListItem>
              <ListDivider />

          </List>)
        })}
    </Box>
  )
}


