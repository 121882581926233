import React from "react";
import Select from "@mui/joy/Select";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Link from "@mui/joy/Link";
import { FormControl, FormLabel, GlobalStyles } from "@mui/joy";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import Chip from "@mui/joy/Chip";
import { ColorPaletteProp } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Skeleton from "@mui/joy/Skeleton";
import Button from "@mui/joy/Button";
import { Add } from "@mui/icons-material";
import Grid from "@mui/joy/Grid";

import { Termin, sortTermins } from "../type";
import { useIndividualTerminStore, useTerminsStore } from "../store/terminsStore";
import RowMenu from "./RowMenu";
import { useUserStore } from "../store/userStore";
import { useShallow } from "zustand/react/shallow";
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';

import { AnimatePresence, easeIn, easeOut, motion } from "framer-motion";
import { TransitionGroup } from "react-transition-group";
import { LinearProgress } from "@mui/joy";

import { useAnimate, stagger } from "framer-motion";
import dayjs from "dayjs";
import { useProjectsStore } from "../store/projectsStore";



export default function TerminsTable(props: {setOpenModal: React.Dispatch<boolean>}) {

  const project_id = useTerminsStore((state) => state.project_id)
  const loading = useTerminsStore((state) => state.loading)
  const termins = useTerminsStore((state) => (state.termins))
  const loggedIn = useUserStore((state) => (state.loggedIn))

  // displayTermins are changed based on search parameters, it depends on dilterValue and descending
  const [displayTermins, setDisplayTermins] = React.useState<Termin[]>(termins)
  const [filterValue, setFilterValue] = React.useState<string>("X")
  const [descending, setDescending] = React.useState<boolean>(false)

  // if the appearance of termins should be animated (is false when applying filter)
  const [animate, setAnimate] = React.useState<boolean>(true)

  React.useEffect(() => {
    setDisplayTermins(termins)
  }, [termins])

  React.useEffect(() => {
    setAnimate(true)
    setFilterValue("X")
  }, [project_id])

  const [disableActions, setDisableActions] = React.useState<boolean>(true)
  const userId = useUserStore((state) => state.userId)
  const owner = useTerminsStore((state) => state.owner)

  React.useEffect(() => {
    setDisableActions((userId !== owner))
  }, [project_id, userId, owner])

  const renderFilters = () => (
    <React.Fragment >
      <FormControl size="sm">
        <FormLabel>Tip termina</FormLabel>
        <Select
          size="sm"
          placeholder="Filtriraj tip termina"
          value={filterValue}
          slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
          onListboxOpenChange={() => setAnimate(false)
          }
          onChange={(
            event: React.SyntheticEvent | null,
            newValue: string | null,
            ) => {
              if (newValue !== null) {setFilterValue(newValue)}
              if (newValue === "X") {
                setDisplayTermins(termins);
                return;
              }
              let newTermins: Termin[] = termins.filter((termin) => termin.termin_type === newValue)
              setDisplayTermins(newTermins);
            }}
        >
          <Option value="X">Vsi</Option>
          <Option value="V">Vaja</Option>
          <Option value="N">Nastop</Option>
          <Option value="K">Koncert</Option>
        </Select>
      </FormControl>
    </React.Fragment>
  );

  if (termins.length === 0 && !loading) return (<></>)

  return (
    <React.Fragment>
    <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
          <Grid
        container
        sx={{
          py: 2,
          display: { xs: 'none', sm: 'flex'},
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
      <Grid
        className="SearchAndFilters-tabletUp"
        xs={8}
        sx={{
          borderRadius: 'sm',
          display: { xs: 'none', sm: 'flex' },
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
      {renderFilters()}
      </Grid>
      <Grid
        xs={3}
        sx={{
          pt: 3,
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button
              disabled={disableActions}
              color="primary"
              startDecorator={<Add />}
              size="sm"
              onClick={() => {
                props.setOpenModal(true)
              }}
            >
              Dodaj termin
          </Button>
      </Grid>
      </Grid>
      <Sheet
        //className="OrderTableContainer"
        variant="outlined"
        sx={{
          backgroundColor: 'white',
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          maxHeight: { sm: '50vh', md: '60vh', lg: '70vh'}
        }}>
        <motion.div
          initial={{scaleY: 0.7}}
          animate={{scaleY: 1}}
        >
        <Table
          variant="plain"
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
            <th style={{ width: 90, textAlign: 'center', padding: '12px 6px' }}>Tip termina
             </th>
              <th style={{ width: 180, textAlign: 'center', padding: '12px 6px' }}>
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() => {
                    setDescending(!descending)
                    setDisplayTermins(sortTermins(displayTermins, descending))
                  }}
                  fontWeight="lg"
                  endDecorator={<ArrowDropDownIcon />}
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform:
                        descending === true ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                  }}
                >
                  Datum
                </Link>
              </th>
              <th style={{ width: 140, padding: '12px 6px' }}>Ura</th>
              <th style={{ width: 240, padding: '12px 6px' }}>Lokacija</th>
            </tr>
          </thead>
            { displayTermins.map((termin) => (
          <AnimatePresence mode="wait">
              <motion.tr
                  style={{ overflow: "hidden" }}
                  layout={animate}
                  initial={
                    (animate) ? {
                      opacity: 0, originY: 0, scaleY: 0.7} : {opacity: 0.7}
                  }
                  animate={
                      (dayjs(termin.date, "DD.MM.YYYY", true).hour(+termin.end.split(':')[0]).minute(+termin.end.split(':')[1]) > dayjs()) ? {
                    opacity:1, originY: 1, scaleY: 1 } : {opacity: 0.6, originY: 1, scaleY: 1, backgroundColor: 'rgba(227, 227, 227, 0.25)'} 
                  }
                  exit={
                    (animate) ? {
                      opacity: 0, originY: 0, scaleY: 0.3,
                      transition: {duration: 0.2, ease: easeOut}
                  } : {opacity: 0.7}}
                  transition={(animate) ? {
                    duration: 0.2
                  } : {duration: 0.05}}
                  key={`termin_id_${termin.id.toString()}`}
                >
                <td style={{ textAlign: 'center', width: 120 }}>
                <Typography fontWeight={600} gutterBottom>
                  {(<Chip
                    variant="soft"
                    size="md"
                    color={
                      {
                        V: 'warning',
                        N: 'primary',
                        K: 'success',
                      }[termin.termin_type] as ColorPaletteProp
                    }
                  >                    {{
                      V: "Vaja",
                      N: "Nastop",
                      K: "Koncert",
                    }[termin.termin_type]}
                  </Chip>)}
                  </Typography>
                </td>
                <td style={{textAlign: 'center'}}>
                    <Typography level="body-sm" fontWeight="500" >
                          {termin.date}
                      </Typography>
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <div>
                        <Typography level="body-sm">
                          {termin.start.slice(0,5)} - {termin.end.slice(0,5)}
                          </Typography>
                    </div>
                  </Box>
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Link href={`https://maps.google.com/?q=${termin.location.replaceAll(' ', '+')}`} target="_blank" level="body-sm">
                        {termin.location.split(",")[0]}
                      </Link>
                      <RowMenu termin={termin} setOpenModal={props.setOpenModal} />
                    </Box>
                </td>
                </motion.tr>
         </AnimatePresence>
            ))}
        </Table>
        </motion.div>
        {(loading) ? <AnimatePresence><motion.div
              initial={{opacity: 0.5}}
              animate={{opacity: 1}}
              exit={{opacity: 0 }}
              transition={{
                delay: 0.2,
                duration: 1
              }}
            ><LinearProgress
              sx={{
                '--LinearProgress-thickness': '3px',
              }}
            /></motion.div></AnimatePresence> : <Box sx={{height: '3px'}}></Box>}
              </Sheet>
    </React.Fragment>
  );
}
