import { Button, Card, CardCover, Divider, List, ListItem, ListItemContent, ListItemDecorator, Sheet, Skeleton, Stack, Typography } from "@mui/joy"
import GlobalStyles from "@mui/joy/GlobalStyles"
import { Box } from "@mui/system"
import React from "react"
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton"
import Avatar from "@mui/joy/Avatar"
import IconButton from "@mui/joy/IconButton"
import { Add, CalendarMonth, PublicOffSharp, PublicSharp, Visibility, VisibilityOff } from "@mui/icons-material"
import PublicIcon from '@mui/icons-material/Public';
import { LogoutRounded as LogoutRoundedIcon, LoginRounded as LoginRoundedIcon } from "@mui/icons-material"
import UseShallow from 'zustand/react/shallow'

import { useProjectsStore } from "../store/projectsStore"
import { useTerminsStore } from "../store/terminsStore"
import { closeSidebar } from "../utils"
import { useUserStore } from "../store/userStore"
import { ProjectModal } from "./modals/ProjectModal"
import { useNavigate } from "react-router-dom"
import { TransitionGroup } from "react-transition-group"
import { Collapse } from "@mui/material"
import { useCalendarStore } from "../store/calendarStore"

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export function Sidebar(props: {setOpenProjectModal: React.Dispatch<boolean>}) {

  const name = useUserStore((store) => store.name)
  const userId = useUserStore((store) => store.userId)
  const loggedIn = useUserStore((store) => store.loggedIn)
  const logout = useUserStore((store) => store.logOut)
  const token = useUserStore((store) => store.token)

  const projects = useProjectsStore((state) => (state.projects))
  const fetchProjects = useProjectsStore((state) => (state.fetchProjects))
  const loading = useProjectsStore((state) => (state.loading))

  const project_id = useTerminsStore((state) => state.project_id)
  const setProjectId = useTerminsStore((state) => (state.setProjectId))
  const setProjectName = useTerminsStore((state) => (state.setProjectName))
  const setProjectOwner = useTerminsStore((state) => (state.setOwner))

  const calendarView = useCalendarStore((state) => state.calendarView)
  const setCalendarView = useCalendarStore((state) => state.setCalendarView)

  React.useEffect(() => {
    if (token !== "") {
      fetchProjects()
    }}, [])

  const [open, setOpen] = React.useState<boolean>(false);

  const navigate = useNavigate()

  return(
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divcRX6NFw5sKA7Tdider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigaion-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          }
        }}
        onClick={() => closeSidebar()}
      />
      <Box>
        <Card component="li" variant="plain" sx={{ minHeight: 150, flexGrow: 1, }}>
          <CardCover>
            <img
              src="https://ggs.hudnik.si/images/logo/tranparent_logo_sm.png"
              alt="GGS logo"
            />
          </CardCover>
        </Card>
      </Box>

      <Divider/>
      <Stack
        sx={{
        flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0, // ensures flexbox container doesn't collapse
          overflow: 'auto', // prevent any overflow on main container
        }}
          >
      {loggedIn ? (
      <Box
        sx={{
          minHeight: 0,
          flexGrow: 0,
          display: {xs: 'contents', lg: 'flex'},
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <Typography
          id="projects"
          level="body-xs"
          textTransform="uppercase"
          fontWeight="lg"
          mb={1}
          mt={1}
        >Moji Projekti
        </Typography>
        <List
          size="sm"
          sx={{
            mb: 1,
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
        <TransitionGroup>
          {projects.filter((project) => (project.owner === userId)).map((project) => {
            return (
              <Collapse
                id={project.id.toString()}
              >
              <ListItem sx={{mt: 1}}>
                <ListItemButton
                  selected={project.id == project_id}
                  onClick={() => {
                    setCalendarView(false)
                    setProjectId(project.id)
                    setProjectName(project.name)
                    setProjectOwner(project.owner)
                    closeSidebar()
                  }}
                >
                  <ListItemContent>
                    <Typography level="title-md">
                        <ListItemDecorator>{ project.public_view ? < PublicSharp / > : < PublicOffSharp />}</ListItemDecorator>
                        {project.name} 
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </Collapse>)
          })}
          </TransitionGroup>
          <Button
              disabled={!loggedIn}
              variant="plain"
              color="primary"
              startDecorator={<Add />}
              size="sm"
              onClick={() => { props.setOpenProjectModal(true) }}
              sx={{
                //mt: 1,
                //ml: 1,
                justifyContent: 'left',
              }}
            >
              Dodaj projekt
            </Button>
        </List>
      </Box>) : (<></>) }

      <Box
        sx={{
          minHeight: 0,
          overflow: 'visible',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <Typography
          id="projects"
          level="body-xs"
          textTransform="uppercase"
          fontWeight="lg"
          mb={1}
          mt={1}
        >Javni Projekti
        </Typography>
        <List
          size="sm"
          sx={{
            mb: 1,
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
        <TransitionGroup>
          {projects.filter((project) => (project.owner !== userId)).map((project) => {
            return (
              <Collapse
                id={project.id.toString()}
              >
              <ListItem sx={{mt: 1}}>
                <ListItemButton
                  selected={project.id == project_id}
                  onClick={() => {
                    setCalendarView(false)
                    setProjectId(project.id)
                    setProjectName(project.name)
                    setProjectOwner(project.owner)
                    closeSidebar()
                  }}
                >
                  <ListItemContent>
                    <Typography level="title-md">
                        {project.name}
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </Collapse>)
          })}
          </TransitionGroup>
        </List>
        <Button
            //component="a"
            //href="calendar"
            onClick={() => {
              setCalendarView(!calendarView)
              if (!calendarView) {
                setProjectId(-1)
              } else {
                setProjectId(projects[0].id)
              }
            }}
            startDecorator={<CalendarMonth/>}
            variant="outlined"
            size="sm"
            color="neutral"
          >
          {calendarView ? "Zapri" : "Koledar terminov"}
          </Button>
      </Box>
      </Stack>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Avatar
          variant="outlined"
          size="sm"
        />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{name }</Typography>
        </Box>
        <IconButton size="sm" variant="plain" color="neutral"
          onClick={() => {
            if (loggedIn) {
              logout();
            } else {
              navigate("/login")
            }
          }}
        >
          { loggedIn ? <LogoutRoundedIcon /> : <LoginRoundedIcon /> }
        </IconButton>
      </Box>
    </Sheet>)
}
