import React from 'react';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { ButtonGroup, CssBaseline, CssVarsProvider, Snackbar } from "@mui/joy";
import Box from "@mui/joy/Box";
import { Add, Download, Edit, Opacity, PublicOffSharp, PublicSharp, ShareOutlined } from '@mui/icons-material';
import Skeleton from '@mui/joy/Skeleton';
import { Delete } from '@mui/icons-material';
import { useNavigate, useSearchParams } from "react-router-dom";
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { AnimatePresence, motion } from 'framer-motion';

import Header from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { TerminsList } from './components/TerminsList';
import { useUserStore } from './store/userStore';
import { individualTerminStore, useTerminsStore } from './store/terminsStore';
import TerminsTable from './components/TerminsTable';
import { useIndividualProjectStore, useProjectsStore } from './store/projectsStore';
import { TerminModal } from './components/modals/TerminModal';
import { ProjectModal } from './components/modals/ProjectModal';
import SnackbarInfo from './components/SnackbarInfo';
import { Welcome } from './components/Welcome';
import RemoveModal from './components/modals/RemoveModal';
import { Fade } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { useCalendarStore } from './store/calendarStore';
import MyCalendar from './components/Calendar';
import { useSnackbarStore } from './store/snackbarStore';

export default function Home() {

  const loggedIn = useUserStore((state) => (state.loggedIn))
  // Retrieve User ID for enabling actions
  const userId = useUserStore((state) => state.userId)

  const navigate = useNavigate()
  React.useEffect(() => {
    fetchProjects()
  }, [])

  const projects = useProjectsStore((state) => state.projects)
  const fetchProjects = useProjectsStore((state) => state.fetchProjects)
  const individualProject = useIndividualProjectStore((state) => state.project)
  const setProject = useIndividualProjectStore((state) => state.setProject)
  const setEditing = useIndividualProjectStore((state) => state.setEditing)

  const {termins, owner, project_id, setProjectId, projectName, setProjectName, loading } = useTerminsStore(useShallow((state) => ({
    termins: state.termins,
    owner: state.owner,
    project_id: state.project_id,
    setProjectId: state.setProjectId,
    projectName: state.project_name,
    setProjectName: state.setProjectName,
    loading: state.loading,
  })))

  // set project_id and project_name, if in url
  const [searchParams] = useSearchParams();
  let url_id = Number(searchParams.get("id"));
  React.useEffect(() => {
    if ((url_id) && (projectName === 'project__name')) {
      setProjectId(url_id)
      //fetchProjects()
      let project = projects.filter((project) => (project.id === url_id))
        if (project.length > 0) {
          setProjectName(project[0].name)
        }
    }}, [projects])

  // Termin and remove modal states
  const [openTerminModal, setOpenTerminModal] = React.useState<boolean>(false)
  const [openRemoveModal, setOpenRemoveModal] = React.useState<boolean>(false)
  const [openProjectModal, setOpenProjectModal] = React.useState<boolean>(false)

  //Fade in
  const [fadeIn, setFadeIn] = React.useState<boolean>(true)

  // Calendar state
  const calendarView = useCalendarStore((state) => state.calendarView)

  const [projectOwner, setProjectOwner] = React.useState<number | undefined>(undefined)
  const [disableActions, setDisableActions] = React.useState<boolean>(true)

  React.useEffect(() => {
    setDisableActions((userId !== owner))
  }, [project_id, userId, owner])

  return (
    <CssVarsProvider>
      <CssBaseline />
      <Box sx={{ 
        display: 'flex', 
        minHeight: '100dvh',
        alignItems: 'space-between',
      }}>
      <>
        <Header />
        <Sidebar setOpenProjectModal={setOpenProjectModal} />
        <Box
          sx={{
          px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(25px + var(--Header-height))',
              sm: 'calc(25px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
        {calendarView ? 
          (
            <AnimatePresence >
            <motion.div
              initial={{opacity: 0.7, scaleY: 0.95}}
              animate={{opacity: 1, scaleY: 1}}
              exit={{opacity: 0.7, scaleY: 0.95}}
            >
              <MyCalendar />
            </motion.div>
            </AnimatePresence>
        ): (<>
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            mt: 2,
            gap: 1,
            flexDirection:  { xs: 'row', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <motion.div 
            key={projectName}
            initial={{  opacity: 0 }}
            animate={{  opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}  

          > 
          <Typography level="h1" component="h1">
            <Skeleton loading={projectName === 'project__name'}>
              {projectName} 
              {/*{projects.find((project) => project.id === project_id)?.public_view ? <PublicSharp/> : <PublicOffSharp/>}*/} 
            </Skeleton>
          </Typography>
          </motion.div>
          <ButtonGroup>
          <Button
              component="a"
              href={"https://hudniki.pythonanywhere.com/endpoints/pdf/" + project_id }
              color="primary"
              variant="outlined"
              startDecorator={<Download />}
              size="sm"
            >
              Izvozi urnik (PDF)
            </Button>
            <Button
              component="a"
              onClick={() => {
                const shareData = {
                  title: projectName,
                  text: `Deli hiper povezavo do projekta '${projectName}'`,
                  url: "https://admin.glasbagrevsvet.si/?id=" + project_id,
                }
                if (window.navigator.share && window.navigator.canShare(shareData)) {
                  window.navigator.share(shareData)
                } else {
                  window.navigator.clipboard.writeText(shareData.url)
                }
                useSnackbarStore.getState().setInfo("Povezava kopirana v odložišče!")
                useSnackbarStore.getState().setColor("success")
                useSnackbarStore.getState().setOpen(true)
              }}
              color="primary"
              variant="outlined"
              startDecorator={<ShareOutlined/>}
            >
              Deli projekt
            </Button>
            </ButtonGroup>
        <Box
          sx={{
          display: { xs: 'flex', sm: 'none' },
          }}
        >
          <Button
            disabled={disableActions}
            color="primary"
            startDecorator={<Add />}
            size="sm"
            onClick={() => {
              setOpenTerminModal(true)
            }}
            sx={{
            }}
          >
            Dodaj termin
          </Button>
        </Box>

        </Box>

        <Box
          component="main"
          className="MainContent"
          mb={1}
          sx={{
            gap: 1,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            justifyContent: 'start',
          }}
        >
        { ((termins.length === 0) && (!loading)) ? (
          <AnimatePresence mode="wait">
          <motion.div
            initial={{opacity:0}}
            animate={{opacity:1}}
            exit={{opacity: 0}}
          >
          <Card variant="outlined"
            //className="collapseIn"
            sx={{
              mt: 5,
            }}
          >
            <CardContent>
                    <Typography level="title-lg">Ni terminov.</Typography>
                    <Box
          sx={{
            mt: 2,
            display: {xs: 'none', sm: 'flex'},
            flexDirection: 'row',
          }}
        >
          <Button
              disabled={disableActions}
              color="primary"
            startDecorator={<Add />}
            size="sm"
            onClick={() => {
              setOpenTerminModal(true)
            }}
            sx={{

            }}
          >
            Dodaj termin
          </Button>
        </Box>
            </CardContent>
          </Card></motion.div>
          </AnimatePresence>
        ) : <></>}

        {/* Display termins with either list or table */}
          <TerminsList setOpenModal={setOpenTerminModal} />
          <TerminsTable setOpenModal={setOpenTerminModal} />
          <motion.div layout
            transition={{duration: 0.1}}
          >
          <Box
            sx={{
              bottom: 0,
              display: 'flex',
              mt: 2,
              mb: 2,
              gap: 1,
              flexDirection: { xs: 'row', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
          <Button
              disabled={disableActions}
              color="primary"
              variant="outlined"
              startDecorator={<Edit />}
              size="sm"
              onClick={() => {
                const setIndividualProject = projects.find((project) => project.id === project_id)
                if (setIndividualProject !== undefined) {
                  setEditing(true)
                  setProject(setIndividualProject)
                }
                setOpenProjectModal(true)
              }}
            >
              Uredi projekt
            </Button>
          <Button
              disabled={disableActions}
              color="danger"
              startDecorator={<Delete />}
              size="sm"
              onClick={() => {
                setOpenRemoveModal(true)
              }}
            >
              Odstrani projekt
            </Button>
          </Box>
          </motion.div>
        </Box>
        </>)}
      </Box>
      </>
      <TerminModal open={openTerminModal} setOpen={setOpenTerminModal}/>
      <RemoveModal open={openRemoveModal} setOpen={setOpenRemoveModal} />
      <ProjectModal open={openProjectModal} setOpen={setOpenProjectModal} />
      <SnackbarInfo />
      </Box>
    </CssVarsProvider>
  )
}
