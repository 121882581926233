import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios'

import { createProject, createTermin, Project, sortTermins, Termin } from '../type'
import { useUserStore } from './userStore'
import { warn } from 'console'
import { useSnackbarStore } from './snackbarStore'
import axiosRetry from 'axios-retry'
import { useProjectsStore } from './projectsStore'

// AXIOS RETRY

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay, // Exponential back-off
  retryCondition: axiosRetry.isRetryableError,
  onRetry: (retryCount, error, requestConfig) => useSnackbarStore.getState().showSnackbar("danger", "Terminov ni mogoče naložiti: " + error.message)
})

// TERMINS

type terminsStore = {
  owner: number
  setOwner: (owner: number) => void
  project_id: number
  clearTermins: () => void
  clearTerminsStore: () => void
  setProjectId: (id: number) => void
  project_name: string
  setProjectName: (name: string) => void
  termins: Termin[]
  fetchTermins: () => void
  loading: boolean
}

export const useTerminsStore = create<terminsStore>()(((set, get) => ({
  // id of the current project, to which the termins should belog
  // if the id is -1, no project is selected
  owner: -2,
  setOwner: (owner: number) => {
    set({owner: owner})
  },
  project_id: -1,
  clearTermins: () => set({ termins: [] }),
  clearTerminsStore: () => set({
    termins: [],
    project_id: -1,
    project_name: "project__name",
  }),
  setProjectId: (id) => {
    set({ project_id : id })
    //
    // TODO
    // the following code crashes, because it does not wait for the fetchProjects to be completed
    //
    //if (id !== -1) {
    //  const fetchProjects = useProjectsStore.getState().fetchProjects
    //  const projects = useProjectsStore.getState().projects
    //  console.log(projects)
    //  const newProject = projects.filter((project) => project.id === id)[0]
    //  console.log(newProject)
    //  set({ project_name: newProject.name })
    //}
  },
  project_name: "project__name",
  setProjectName: (name) => set({ project_name : name}),
  // termins data
  termins: [],

  fetchTermins: async () => {
    if (get().project_id !== -1) {
    if (!useIndividualTerminStore.getState().loading) {get().clearTermins()}
    set({ loading: true })
    const id = get().project_id
    await axios.get(`https://hudniki.pythonanywhere.com/endpoints/termins?project=${id}`).then((response) => {
      if (id !== get().project_id) {
        // if Ids do not match ignore successful request
        //useSnackbarStore.getState().showSnackbar("danger", "Prišlo je do napake pri nalaganju terminov. Poskusi osvežiti stran.")
      } else {
        set({
          termins: sortTermins(response.data),
          loading: false
        })
    }}
    ).catch((error) => {
      //get().fetchTermins()
      useSnackbarStore.getState().showSnackbar("danger", "Prišlo je do napake pri nalaganju terminov: " + error.code)
      console.log(error)
    })
  }},
  // if a request is going on
  loading: true,
})))

// Individual termins for adding, editing and deleting termins

export type individualTerminStore = {
  open: boolean
  setOpen: (b: boolean) => void
  termin: Termin
  setTermin: (termin: Termin) => void
  clearTermin: () => void
  postTermin: () => void
  updateTermin: () => void
  deleteTermin: () => void
  loading: boolean
  editing: boolean
  setEditing: (b: boolean) => void
}

export const useIndividualTerminStore = create<individualTerminStore>()(devtools((set, get) => ({
  open: false,
  setOpen: (b: boolean) => set({ open: b }),
  termin: createTermin(-1, -1, "termintype", "9.9.2024", "10:00:00", "11:00:00", "location"),
  setTermin: (termin: Termin) => set({ termin: termin }),
  clearTermin: () => set({ termin:  createTermin(-1, -1, "termintype", "9.9.2024", "10:00:00", "11:00:00", "location"), }),
  postTermin: async () => {
    set({ loading: true })
    const token = useUserStore.getState().token
    await axios.post('https://hudniki.pythonanywhere.com/endpoints/termins/', {
        'project': get().termin.project,
        'termin_type': get().termin.termin_type,
        'date': get().termin.date,
        'start': get().termin.start,
        'end': get().termin.end,
        'location': get().termin.location
      }, {
        headers: {
            'Authorization': `Token ${token}`
        }
      }).then(
        (response) => {
            useSnackbarStore.getState().showSnackbar("success", "Termin uspešno dodan!")
        }
      ).catch(
        (error) => {
          console.log(error)
          let msg = ""
          if (error.response.data.detail) {
            msg = error.response.data.detail
          } else {
            msg = error.message
          }
          useSnackbarStore.getState().showSnackbar("danger", msg)
        }
      );
      useTerminsStore.getState().fetchTermins()
    set({ loading: false })

  },
  updateTermin: async () => {
    set({ loading: true })
    if (get().termin.id !== -1) {
      const token = useUserStore.getState().token
      await axios.patch(`https://hudniki.pythonanywhere.com/endpoints/termins/${get().termin.id}/`, {
              'project': get().termin.project,
              'termin_type': get().termin.termin_type,
              'date': get().termin.date,
              'start': get().termin.start,
              'end': get().termin.end,
              'location': get().termin.location
          }, {
              headers: {
                  'Authorization': `Token ${token}`
              }
          }).then(
              (response) => {
                  useSnackbarStore.getState().showSnackbar("success", "Termin uspešno posodobljen!")
              }
          ).catch(
              (error) => {
                let msg = ""
                if (error.response.data.detail) {
                  msg = error.response.data.detail
                } else {
                msg = error.message
                }
                useSnackbarStore.getState().showSnackbar("danger", msg)
              });
          useTerminsStore.getState().fetchTermins()
          set({ loading: false })
    }
  },
  deleteTermin: async () => {
    set({ loading: true })
    await axios.delete(`https://hudniki.pythonanywhere.com/endpoints/termins/${get().termin.id}`,
                       { headers: { 'Authorization': `Token ${useUserStore.getState().token}` }}
                      )
        .then((response) => {
            useSnackbarStore.getState().showSnackbar("warning", "Termin uspešno odstranjen!")
            useTerminsStore.setState((state) => ({ termins: state.termins.filter((termin:Termin) => (termin.id !== get().termin.id)) }))
        })
        .catch((err) => {
            useSnackbarStore.getState().showSnackbar("danger", err)
        })
        //useTerminsStore.getState().fetchTermins()
        set({loading: false})
  },
  loading: false,
  editing: false,
  setEditing: (b: boolean) => set({ editing: b }),
})))

