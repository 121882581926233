import {
  Calendar as BigCalendar,
  momentLocalizer,
  Views,
  View,
} from "react-big-calendar";
import moment from "moment";
import { useState } from "react";
import { Termin } from "../type";
import { useTerminsStore } from "../store/terminsStore";
import axios from "axios";
import { useProjectsStore } from "../store/projectsStore";
import { useCalendarStore } from "../store/calendarStore";
import React from "react";

const localizer = momentLocalizer(moment);

// TODO!

function formEventsArray(termins: Termin[]) {

  const events = termins.map((termin) => {
    const start_date = termin.date.split(".")[2] + "-" + termin.date.split(".")[1] + "-" + termin.date.split(".")[0] + "T" + termin.start
    const end_date = termin.date.split(".")[2] + "-" + termin.date.split(".")[1] + "-" + termin.date.split(".")[0] + "T" + termin.end
    return {
      id: termin.id,
      title: termin.location.split(",")[0],
      start: moment(start_date).toDate(),
      end: moment(end_date).toDate(),
    }})
  return events
}

export default function MyCalendar() {
  const [view, setView] = useState(Views.WEEK);
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState<{id: number, title: string, start: Date, end: Date}[]>([])

  const termins = useCalendarStore((state) => state.termins)
  const fetchTermins = useCalendarStore((state) => state.fetchTermins)
  const setCalendarView = useCalendarStore((state) => state.setCalendarView)

  const setProjectId = useTerminsStore((state) => state.setProjectId)

  React.useEffect(() => {
    fetchTermins()
    setEvents(formEventsArray(termins))
  }, [])

  React.useEffect(() => {
    setEvents(formEventsArray(termins))
  }, [termins])

  console.log(events[0])
  function handleDoubleClick(calEvent: {id: number, title: string, start: Date, end: Date}) {
    setProjectId(termins.filter((termin) => termin.id === calEvent.id)[0].project)
    setCalendarView(false)
  }

  return (
    <div style={{ height: "95vh" }}>
      <BigCalendar
        localizer={localizer}
        defaultView={Views.WEEK}
        //view={view}
        date={date}
        //onView={(view) => setView(view)}
        onNavigate={(date) => setDate(date)}
        events={events}
        onDoubleClickEvent={(calEvent) => handleDoubleClick(calEvent)}
        startAccessor="start"
        endAccessor="end"
      />
    </div>
  );
}

