import * as React from "react";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import { Card, CssVarsProvider, IconButton, useColorScheme } from "@mui/joy";
import axios from "axios";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

import { redirect } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


import { useUserStore } from "../store/userStore";
import { useShallow } from "zustand/react/shallow";

interface FormElements extends HTMLFormControlsCollection {
    username: HTMLInputElement;
    password: HTMLInputElement;
    persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}


export function Login() {

  const {loggedIn, user, logIn, logOut, loading } = useUserStore(
    useShallow((state) => ({
      loggedIn: state.loggedIn,
      user: state.username,
      logIn: state.logIn,
      logOut: state.logOut,
      loading: state.loading,
    }))
  )

  const [username, setUsername] = React.useState<string>("username")
  const [password, setPassword] = React.useState<string>("password")

  const navigate = useNavigate();
  React.useEffect(() => {
    if (loggedIn) {
      navigate("/")
    }
  }, [loggedIn])

  return (
    <>
            <CssBaseline />

            <Card
                sx={{
                    display: "flex",
                    maxWidth: "800px",
                    justifyContent: "center",
                    mx: "auto",
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 6 },
                }}
            >
                <Box sx={{ mb: 1, mt: 3 }}>

                    <Box
                        sx={{
                            position: "absolute",
                            top: "0.875rem",
                            left: "1rem",
                        }}
                    >
                    </Box>

                    <Typography level="title-lg">GGS-ADMIN</Typography>
                    <Typography level="body-sm">
                        Aplikacija za organizacijo projektov Glasba gre v svet
                    </Typography>
                    <Box
                        component="main"
                        sx={{
                            my: "auto",
                            py: 2,
                            pb: 5,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            width: 400,
                            maxWidth: "100%",
                            mx: "auto",
                            borderRadius: "sm",
                            "& form": {
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: "hidden",
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mt: 2 }}>
                            <form
                                onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                                    event.preventDefault();
                                    const formElements = event.currentTarget.elements;
                                    logIn(formElements.username.value, formElements.password.value);
                                }}
                            >
                                <FormControl required>
                                    <FormLabel>Uporabniško ime</FormLabel>
                                    <Input type="username" name="username" />
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Geslo</FormLabel>
                                    <Input type="password" name="password" />
                                </FormControl>
                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <Button 
                                      loading={loading}
                                      type="submit" 
                                      fullWidth>
                                        Potrdi
                                    </Button>
                                </Stack>
                                <Button
                                    variant="soft"
                                    onClick={() => {
                                      navigate("/")
                                    }}
                                >
                                    Nadaljuj kot gost
                                </Button>
                            </form>
                            <Stack gap={4} sx={{ mt: 2 }}>
                            {/*<LoginError loginError={loginError} />*/}
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            </Card>
        </>
  )
}
